<template>
  <div>
    <div v-if="isResponsive">
      <div class="content-img-bg" style="height: 240px;">
        <div class="pa-3">
          <!-- :href="bReturnToCart ? '/payment-process':'/vendor'" -->
          <v-btn @click="$router.go(-1)" text class="pl-0">
            <v-icon class="icon-return" size="15px">
              mdi-arrow-left
            </v-icon>
            <span class="text-return mon-regular">{{
              texts.vendorDetail.textReturn
            }}</span>
          </v-btn>
        </div>
        <div class="content-image">
          <img v-if="logoImage !== ''" :src="logoImage" alt="" />
          <img v-else src="@/assets/images/placeholder-image.jpeg" alt="" />
        </div>
      </div>
      <div
        style="margin-top: 100px;"
        class="display-flex align-items-center justify-content-center"
      >
        <div class="display-flex align-items-center">
          <v-rating
            :value="arrVendorDetail.dRating"
            length="5"
            color="#FFC92A"
            background-color="#C6C6C8"
            dense
            size="25px"
            readonly
          ></v-rating>
          <p
            :style="arrVendorDetail.dRating == 0 ? { color: '#C6C6C8' } : {}"
            class="text-calif-num mon-medium ml-1"
          >
            {{ arrVendorDetail.dRating > 5 ? 5 : arrVendorDetail.dRating }}
          </p>
        </div>
      </div>
      <p
        style="color: #000; font-size: 25px; text-align: center;"
        class="text-title mon-bold"
      >
        {{ arrVendorDetail.sName }}
      </p>
      <div class="ml-5">
        <div class="content-options">
          <template>
            <v-tabs v-model="tab" background-color="transparent">
              <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
              <v-tab class="tabs-profile mon-regular">{{
                texts.vendorDetail.tab.generalInformation
              }}</v-tab>
              <v-tab class="tabs-profile mon-regular">{{
                texts.vendorDetail.tab.products
              }}</v-tab>
            </v-tabs>
          </template>
        </div>
      </div>
      <div class="pa-4">
        <template>
          <v-tabs v-model="tab" background-color="transparent">
            <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
            <v-tab class="tabs-profile mon-regular">{{
              texts.vendorDetail.tab.generalInformation
            }}</v-tab>
            <v-tab class="tabs-profile mon-regular">{{
              texts.vendorDetail.tab.products
            }}</v-tab>
          </v-tabs>
        </template>
        <template>
          <v-tabs-items :touchless="true" v-model="tab">
            <v-tab-item class="pt-5">
              <vendor-detail-content-tab-one-layout
                :texts="texts"
                :arrVendorDetail="arrVendorDetail"
              ></vendor-detail-content-tab-one-layout>
            </v-tab-item>
            <v-tab-item class="pt-5">
              <vendor-detail-content-tab-two-layout
                :texts="texts"
                :arrVendorDetail="arrVendorDetail"
              ></vendor-detail-content-tab-two-layout>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </div>
      <br /><br /><br />
    </div>
    <div v-else>
      <div class="content-img-bg">
        <div class="absolute-calif display-flex align-items-center mb-2">
          <v-rating
            :value="arrVendorDetail.dRating"
            length="5"
            color="#FFC92A"
            background-color="#C6C6C8"
            dense
            size="25px"
            readonly
          ></v-rating>
          <p
            :style="arrVendorDetail.dRating == 0 ? { color: '#C6C6C8' } : {}"
            class="text-calif-num mon-medium ml-1"
          >
            {{ arrVendorDetail.dRating > 5 ? 5 : arrVendorDetail.dRating }}
          </p>
        </div>
        <v-container class="v-container-fluid" fluid>
          <v-row>
            <v-col cols="12">
              <!-- button return -->
              <!-- :href="bReturnToCart ? '/payment-process' : '/vendor'" -->
              <v-btn
              @click="$router.go(-1)"
                text
                class="pl-0"
              >
                <v-icon class="icon-return" size="15px">
                  mdi-arrow-left
                </v-icon>
                <span class="text-return mon-regular">{{
                  texts.vendorDetail.textReturn
                }}</span>
              </v-btn>
              <div class="display-flex align-items-center aument-content">
                <div
                  style="position: relative; margin-right: 200px; margin-top: -90px;"
                >
                  <div class="content-image">
                    <img v-if="logoImage !== ''" :src="logoImage" alt="" />
                    <img
                      v-else
                      src="@/assets/images/placeholder-image.jpeg"
                      alt=""
                      style="background-color: #F4F4F4;"
                    />
                  </div>
                </div>
                <div class="ml-5">
                  <div class="content-options">
                    <p class="text-title mon-bold">
                      {{ arrVendorDetail.sName }}
                    </p>
                    <template>
                      <v-tabs v-model="tab" background-color="transparent">
                        <v-tabs-slider
                          class="separator-line-tab"
                        ></v-tabs-slider>
                        <v-tab class="tabs-profile mon-regular">{{
                          texts.vendorDetail.tab.generalInformation
                        }}</v-tab>
                        <v-tab class="tabs-profile mon-regular">{{
                          texts.vendorDetail.tab.products
                        }}</v-tab>
                      </v-tabs>
                    </template>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div>
        <v-container class="v-container-fluid" fluid>
          <v-row>
            <v-col cols="12">
              <div class="container-tabs">
                <div class="hide-content-options">
                  <p class="text-title mon-bold">{{ arrVendorDetail.sName }}</p>
                  <template>
                    <v-tabs v-model="tab" background-color="transparent">
                      <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
                      <v-tab class="tabs-profile mon-regular">{{
                        texts.vendorDetail.tab.generalInformation
                      }}</v-tab>
                      <v-tab class="tabs-profile mon-regular">{{
                        texts.vendorDetail.tab.products
                      }}</v-tab>
                    </v-tabs>
                  </template>
                </div>
                <template>
                  <v-tabs-items :touchless="true" v-model="tab">
                    <v-tab-item>
                      <vendor-detail-content-tab-one-layout
                        :texts="texts"
                        :arrVendorDetail="arrVendorDetail"
                      ></vendor-detail-content-tab-one-layout>
                    </v-tab-item>
                    <v-tab-item>
                      <vendor-detail-content-tab-two-layout
                        :texts="texts"
                        :arrVendorDetail="arrVendorDetail"
                      ></vendor-detail-content-tab-two-layout>
                    </v-tab-item>
                  </v-tabs-items>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "ViewArticleSearchbarLayout",
  data() {
    return {
      tab: 0,
      arrVendorDetail: {},
      logoImage: "",
      isResponsive: false,
      bReturnToCart: false,
    };
  },
  beforeMount() {
    this.commingFromCart();
    this.getVendorDetail();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // switches to items tab if the user comes directly from cart
    commingFromCart: function () {
      if (this.$store.state.bComesFromCart) {
        this.tab = 1;
        this.bReturnToCart = true;
        this.$store.commit("setCommingFromCart", false);
      }
    },
    handleResize: function() {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    getVendorDetail: function() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}`,
        config
      )
        .then((response) => {
          let arr = response.data.results;
          this.arrVendorDetail = arr;

          if (Object.keys(arr.oVendorLogo.oImages).length > 0)
            this.logoImage = arr.oVendorLogo.oImages.md;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.language) {
        this.getVendorDetail();
      }
    },
  },
};
</script>

<style>
.container-profile .v-slide-group__content {
  margin-bottom: 4px !important;
}
</style>

<style scoped>
.absolute-calif {
  position: absolute;
  top: 50%;
  right: 30px;
}

.text-calif {
  text-align: right;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif-num {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 15px;
}

.container-tabs {
  margin-top: 100px;
}

.text-title {
  text-align: left;
  font-size: 55px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
}

.content-image {
  width: 200px;
  height: 200px;
  position: absolute;
  background-color: white;
  border-radius: 19px;
  top: 0;
  left: 0;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 19px;
  }
}

.separator-line-tab {
  background: transparent linear-gradient(268deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  margin-left: 2px;
}

.tabs-profile {
  font-size: 14px;
  text-transform: initial;
  letter-spacing: 0px;
  text-align: center;
  color: #fff !important;
}

.v-tab--active {
  font-family: "montserrat-bold", sans-serif;
  color: #fff !important;
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: 0;
}

.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0;
}

.content-img-bg {
  background: url("../../../assets/images/bg-banner-vendor.png");
  position: relative;
}

.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
  color: #ffffff;
}

.hide-content-options {
  display: none;
}

@media (max-width: 600px) {
  .content-options {
    display: none;
  }
  .aument-content {
    margin-top: 65px;
  }
  .hide-content-options {
    display: block;
    margin-top: 140px;
  }
  .hide-content-options .tabs-profile {
    color: #000 !important;
  }
  .text-title {
    color: #000 !important;
    font-size: 25px;
  }
}

@media (max-width: 960px) {
  .content-image {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .tabs-profile {
    color: #000 !important;
  }
}
</style>
